@import "./configs/styles.css";

.spaBtn {
  background-color: #23375a;
  color: white;
  margin: 10px;
  padding: 5px;
  text-align: center;
  font-size: 14px;
  width: 100%;
}

.spaImg {
  background-color: var(--primary-light-color);
  color: white;
  margin: 10px;
  padding: 5px;
  text-align: center;
  font-size: 14px;
  width: 100%;
}

.spaBtnDiv {
  width: 30%;
  background-color: var(--primary-light-color);
}

/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
:root {
  --primary-dark-color: #666798;
  --primary-light-color: white; /*#e6e6e6;*/
  --secondary-dark-color: #f2a460;
  --secondary-light-color: #fbfbfb;

  --generic-border-radius: 10px;
  --generic-border-style: none;
  --generic-margin-s: 5px;
  --generic-padding-s: 5px;
}

.App {
  font-family: Arial, Helvetica, sans-serif;
  /*text-align: center;*/
  /*background-color: #e6e6e6;*/
  background-color: var(--secondary-dark-color);
}

.body {
  margin: 0px;
  background-color: var(--primary-light-color);
}

.p {
  margin: 0xp;
}

.HeaderToolBar {
  width: 100%;
  height: 1%;
  color: var(--primary-dark-color);
  background-color: var(--primary-light-color);
  display: flex;
  flex-direction: row-reverse;
  align-content: flex-end;
  padding-right: 1%;

  /* padding-top: 110px;*/
}

.HeaderToolBarItem {
  margin-right: 15px;
  color: var(--primary-dark-color);
  text-decoration: none;
  font-size: 1vw;
}

.HeaderToolBarItem > a {
  text-decoration: none;
  color: var(--primary-light-color);
}

.HeaderToolBarItemDisabled {
  margin-right: 15px;
  color: gray;
  text-decoration: none;
  font-size: 1vw;
}

.HeaderToolBarItemDisabled > a {
  text-decoration: none;
  color: gray;
}

a :hover {
  color: var(--secondary-dark-color);
}

.HomeArtisetFrame {
  width: 100%;
  border-style: none;
  /*border: solid 5px red;*/
  background-color: var(--primary-light-color);
  /*margin: 10px;*/
  margin-bottom: 0px;
  height: auto;
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  border-radius: 0px 0px 0px 80% / 0px 0px 0px 5%;
}

.artisetLogoFrame {
  width: 100%;
  margin-top: 0%;
  /*padding: 15px;*/
  /*text-align: center;*/
  margin-left: 0%;
  /*display: flex;
  align-items: center;*/
}

.artisetLogo {
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(46, 46, 46, 0.2),
    0 2px 6px 0 rgba(46, 46, 46, 0.2);
}

.artisetCardFrame {
  width: 100%;
  height: auto;
  /*padding: 10px 10px 10px 0px;*/
  color: var(--primary-light-color);
  /*display: flex;
  align-items: center;*/
  text-align: justify;
}

.artisetCard {
  width: 100%;
  height: 60%;
  background-color: inherit;
  color: var(--primary-dark-color);
  /*margin-left: 0px;*/
  /*margin-right: 5%;*/
  /*margin-top: 10px;*/
  margin-bottom: 15%;
  /*padding: 30px;*/
  /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
  /*border-radius: 7px;*/
  font-size: 1.8vw;
  /*display: flex;
  justify-content: center;
  align-content: center;*/
  /*flex-direction: column;*/
  /*text-indent: 20px;*/
  /*letter-spacing: 2px;*/
  /*word-spacing: 15px;
  line-height: 1;*/
}

.artisetCardEnhance {
  font-size: 3vw;
  padding: 5px;
}

.HomeDestaqueBackground {
  background-color: var(--primary-light-color);
  margin: 0px;
  padding: 0px;

  height: 50%;
  width: 100%;
}

.DestaqueFrame {
  width: 100%;
  /*border-style: none;*/
  background-color: var(--primary-light-color);
  color: var(--secondary-light-color);
  /*margin: 10px;*/
  margin-bottom: 0px;
  margin-top: 0px;

  /*padding: 40px;*/
  /*height: 50%;*/
  display: flex;
  flex-direction: column;
  /*justify-content: flex-end;*/
  /*align-content: flex-end;*/
  /*vertical-align: middle;*/
}

.DestaqueHeader {
  width: 100%;
  height: 10%;
  padding: 0px;
  background-color: var(--primary-light-color);
  border-radius: 20% 0px 0px 0px / 7%;
  /*background-color: var(--secondary-dark-color);*/
  display: flex;
  flex-direction: row;
  /*align-content: start;
  text-align: start;
  justify-content: flex-start;*/
}

.DestaqueHeaderTitleFrame {
  background-color: var(--primary-light-color);
  width: 100%;
  height: 20%;
  color: var(--secondary-light-color);
  /*color: var(--secondary-dark-color);*/
  /*font-weight: bolder;*/
  /*margin-left: auto;
  margin-top: auto;*/
  margin: 0px;
  padding: 0px; /*0px 0px 0px 5%;*/
  /*justify-content: center;*/
  text-align: center;
  /*border: solid 1px yellow;*/
}

.DestaqueHeaderTitle {
  width: 100%;
  height: 20%;
  color: var(--primary-light-color);
  /*color: var(--secondary-dark-color);*/
  background-color: var(--secondary-dark-color);
  font-size: 2vw;
  /*font-weight: bolder;*/
  /*margin-left: auto;
  margin-top: auto;*/
  border-radius: 0% 0% 200% 0% / 0px 0px 120% 0%;
  /*margin: 0px 0px 0px 0px;*/
  padding: 0px 0px 0px 15px;
  /*justify-content: center;*/
  text-align: left;
  /*border: solid 1px yellow;*/
}

.DestaqueHeaderBarFrame {
  width: 1%;
  height: auto;
  padding: 0px;
  margin: 0px;
  background-color: var(--secondary-dark-color);
}

.DestaqueHeaderBar {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  /*color: var(--primary-light-color);*/
  border-radius: 200% 3% 0px 0px / 170% 2% 0% 1%; /*50% 100% 0px 0px / 85% 20%;*/
  background-color: var(--primary-dark-color);
  /*border: solid 1px red;*/
  /*background-color: var(--secondary-dark-color);*/
  /*display: flex;
  flex-direction: row;
  align-content: start;
  text-align: start;
  justify-content: flex-start;*/
}

.DestaqueContent {
  width: 100%;
  height: auto;
  margin: 50px 0px 0px 0px;
  padding: 0px 0px 10px 0px;
  /*padding: 0px;*/
  /*padding-top: 30px;
  padding-bottom: 5px;*/
  /*color: var(--secondary-light-color);*/
  background-color: var(--primary-light-color);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.DestaqueCardFrame {
  width: 100%;
  height: auto;
  margin: 10px;
  padding: 3%;
  background-color: var(--primary-light-color);
  color: var(--primary-light-color);
  display: flex;
  flex-direction: row;
  -ms-flex-align: center;
}

.DestaqueBigTitle {
  color: var(--secondary-light-color);
  /*color: var(--secondary-dark-color);*/
  background-color: var(--secondary-dark-color);
  font-size: 2.5vw;
  font-weight: bolder;
  margin-left: auto;
  margin-top: auto;

  padding: 0px 0px 0px 0px;
  /*border: solid 1px yellow;*/
}

/*
.DestaqueBigTitleCurve::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 35%;
  height: 30%;
  background-color: red;
  transform: translate(5%, 30%);
}

.DestaqueBigTitleCurve::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100% 50%;
  width: 35%;
  height: 30%;
  background-color: blue;
  transform: translate(-4%, 40%);
}
*/

.DestaqueCardTitle {
  color: var(--primary-dark-color);
  background-color: var(--primary-light-color);
  text-align: center;
  /*height: 70%;*/
  width: 50%;
  font-size: 1.2vw;
  /*display: inline-block;*/
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  align-self: flex-end;
  /*border: solid 1px blue;*/
  /*margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 0px;*/
  padding: 10px;
  /*border-radius: 20% 60% 0px 0px/100% 100% 0px 0px;*/
  text-align: center;
}

.DestaqueCardTitle > p {
  margin: 0px;
}

.DestaqueCard {
  width: 100%;
  height: auto;
  background-color: var(--primary-light-color);
  color: var(--primary-dark-color);
  font-size: 1vw;
  text-align: left;
  /*margin-left: 10px;
  margin-right: 10px;
  margin-top: 0px;
  margin-bottom: 10px;*/
  padding: 25px;
  /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
  /*border-radius: 0px 0px 15% 60% /30% 40%;*/
  /*justify-content: flex-end;*/
  align-content: center;
  /*flex-direction: column;*/
  align-self: flex-end;
  /*border: solid 1px red;*/
}

.DestaqueCardSubtitle > p {
  margin: 0px;
  padding: 0px;
  text-align: right;
}

.DestaqueCardText {
  font-size: 1vw;
  text-align: justify;
}

.DestaqueCardText > p {
  margin: 0px;
  padding: 0px;
  text-align: right;
}

.DestaqueCardWhen {
  font-size: 1.1vw;
  text-align: right;
  font-weight: bold;
  color: var(--primary-light-color);
}

.DestaqueCardWhen > p {
  margin: 0px;
  padding: 0px;
  color: var(--primary-light-color);
}

.DestaqueCardWhere {
  font-size: 1.1vw;
  text-align: right;
  font-weight: bold;
  margin: 0px;
  padding-bottom: 5px;
  color: var(--primary-light-color);
}

.DestaqueCardWhere > p {
  margin: 0px;
  padding: 0px;
}

.DestaqueCardEnhance {
  font-size: 1.5vw;
  padding: 5px;
  color: var(--primary-light-color);
}

/*
.DestaqueCarouselFrame {
  width: auto;
  height: 350px;
  margin-top: 0px;
  margin-left: 2%;
  margin-right: 2%;
  background-color: var(--secondary-dark-color);
  border: solid 1 red;
}*/

.DestaqueImageFrame {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  color: var(--primary-light-color);
  /*border-radius: 20% 0px 0px 0px / 7%;*/
  background-color: var(--primary-dark-color);
  /*background-color: var(--secondary-dark-color);*/
  display: flex;
  flex-direction: column;
  /*align-content: start;
  text-align: start;*/
  align-items: baseline;
  /*justify-content: space-around;*/
}

.DestaqueImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  max-height: 100vh;
  background-color: var(--primary-light-color);
  /*width: auto;*/
  /*margin-left: 5%;
  margin-top: 10%;*/
  margin: 0px;
  padding: 0px;

  /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
}

.DestaqueImageBorder {
  height: 100%;
  width: fit-content;
  /*align-self: flex-start;*/
  /*background-color: var(--primary-dark-color);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 15px;*/
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.DestaqueFooter {
  background-color: var(--primary-light-color);
  margin: 0px;
  padding: 0px;
}

.DestaqueFooterBar {
  background-color: var(--primary-dark-color);
  color: var(--primary-dark-color);
  margin: 0px;
  padding: 0px;
  border-radius: 0px 0px 100% 10% /0px 0px 100% 100%;
}

.ContentFixHeaderMenu {
  height: 100px;
}

.ContentOuterFrameColumn {
  width: 100%;
  min-height: 40%;
  height: 40%;
  background-color: var(--primary-light-color);
  color: var(--primary-dark-color);
  display: flex;
  flex-direction: column;
  /*border: solid rgba(116, 104, 60, 0.747) 1px;*/
}

.ContentOuterFrameRow {
  width: 100%;
  min-height: 40%;
  height: 40%;
  background-color: var(--primary-light-color);
  color: var(--primary-dark-color);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* border: solid magenta 1px;*/
}

.ContentSideMarginLeft {
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  /*   background-color: aqua;*/
}

.ContentSideMarginRight {
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  /* background-color: burlywood;*/
}

.ContentMidle {
  width: 80%;
  height: 50%;
  /*border: solid rebeccapurple 3px;*/
}

.ContentFrameLight {
  width: 100%;
  height: 100%;
  background-color: var(--primary-light-color);
  color: var(--primary-dark-color);
  display: flex;
  flex-direction: column;
  /*min-height: 80vh;*/
  padding-bottom: 10%;
  /*border: solid red 1px;*/
}

.ContentFrameDark {
  width: 100%;
  height: 100%;
  background-color: var(--primary-dark-color);
  color: var(--primary-light-color);
  display: flex;
  flex-direction: column;
  /*border: solid yellow 1px;*/
}

.ContentTitle {
  background-color: inherit;
  color: inherit;
  width: 100%;
  font-size: 30px;
  text-align: center;
  padding: 15px;
}
.ContentSubTitle {
  background-color: inherit;
  color: inherit;
  width: 100%;
  font-size: 25px;
  text-align: center;
  padding: 10px;
}

.ContentContent {
  background-color: inherit;
  color: inherit;
  width: 100%;
  font-size: 15px;
  text-align: left;
  padding: 30px 50px;
}

.ContentContentRow {
  background-color: inherit;
  color: inherit;
  width: 100%;
  font-size: 15px;
  text-align: left;
  padding: 0% 2%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
}
.ContentContentRowWrap {
  background-color: inherit;
  color: inherit;
  width: 100%;
  font-size: 15px;
  text-align: left;
  padding: 0% 2%;
  display: flex;
  /*flex-direction: row;*/
  flex-wrap: wrap;
  flex: 1 1 auto;
  gap: 10%;
  justify-content: space-evenly;
}

.ContentContentColumnWrap {
  background-color: inherit;
  color: inherit;
  width: 100%;
  font-size: 15px;
  text-align: left;
  padding: 0% 2%;
  display: flex;
  flex-direction: column wrap;
  flex: 1 1 auto;
  justify-content: space-around;
}

.ContentContentColumn {
  background-color: inherit;
  color: inherit;
  width: 100%;
  font-size: 15px;
  text-align: left;
  padding: 0% 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ContentContentColumnBox {
  background-color: inherit;
  color: inherit;
  width: 100%;
  font-size: 15px;
  text-align: left;
  padding: 0% 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.QuemSomosFrame {
  width: 100%;
  padding-top: 100px;
  /* height: calc(100% -100px);
  background-color: var(--primary-dark-color);
  color: var(--primary-light-color);
  display: flex;
  flex-direction: column;
  border: solid red 1px;*/
}

.QuemSomosHeader {
  width: 100%;
  background-color: var(--primary-light-color);
  color: var(--primary-dark-color);
  display: flex;
  flex-direction: row-reverse;
  border-radius: 0px 0px 0px 30% /0px 0px 0px 50%;
  /*border: solid 1px blue;*/
}

.QuemSomosHeaderTitle {
  background-color: var(--primary-light-color);
  color: var(--primary-dark-color);
  text-align: center;
  font-size: 1.7vw;
  width: 80%;
  padding-left: 0px;
}

.QuemSomosHeaderTitleMenu {
  background-color: var(--primary-light-color);
  color: var(--primary-dark-color);
  text-align: left;
  font-size: 1.7vw;
  width: 100%;
  padding-left: 15px;
}

.QuemSomosContent {
  width: 100%;
  background-color: var(--primary-light-color);
  color: var(--primary-dark-color);
  display: flex;
  flex-direction: row;
}

.QuemSomosContentFrame {
  width: 100%;
  background-color: var(--primary-light-color);
  color: var(--primary-dark-color);
  display: flex;
  flex-direction: row;
}

.QuemSomosContentRight {
  width: 10%;
}

.QuemSomosContentLeft {
  width: 90%;
}
.QuemSomosContentFrameRight {
  width: 50%;
}

.QuemSomosContentFrameLeft {
  width: 50%;
}

.QuemSomosContactMap {
  min-height: max-content;
  min-width: 50%;
}

.QuemSomosContatosDetailsTop {
  display: flex;
  flex-direction: column;
}

.QuemSomosContactDetailsContent {
  display: flex;
  flex-direction: row;
}

.QuemSomosContextText {
  background-color: var(--primary-light-color);
  color: var(--primary-dark-color);
  width: 100%;
  font-size: 1.2vw;
  padding-top: 2%;
  padding-bottom: 5%;
  padding-left: 5%;
  padding-right: 15%;
  border-radius: 0px 30% 35% 20% /0px 50% 50% 20%;
  display: flex;
  flex-direction: column;
}

.QuemSomosContatosDetails {
  display: flex;
  flex-direction: row;
  font-size: 1.3vw;
}

.QuemSomosContatosDetailsLeft,
.QuemSomosContatosDetailsRight {
  padding: 10px;
  width: 50%;
}

.QuemSomosFooter {
  width: 100%;
  color: var(--primary-dark-color);
  background-color: var(--secondary-light-color);
  display: flex;
  flex-direction: row;
}
.QuemSomosFooterBar {
  width: 100%;
  background-color: var(--primary-light-color);
  color: var(--primary-dark-color);
  display: flex;
  flex-direction: row;
  border-radius: 0px 0px 55% 50% /0px 50% 100% 100%;
  /*border: solid 1px red;*/
}

.DirecaoListItems {
  background-color: inherit;
  color: inherit;
  height: 12%;
  width: 100%;
  font-size: 0.7vw;
  display: flex;
  flex-flow: column;
  /*flex: 1 1 30%;
  max-height: 700px;*/
}

.DirecaoListItem {
  background-color: inherit;
  color: inherit;
  margin: 5px;
  padding: 0px;
  font-size: 1vw;
  /*border-radius: 5px;*/
  /*border-radius: solid 1px;*/
  /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
}

.DirecaoCargo {
  color: inherit;
  font-size: inherit;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
}

.DirecaoName {
  color: inherit;
  font-size: inherit;
  padding: 0px;
  margin: 0px;
}

/*






*/

.EstatutosLink {
  color: var(--secondary-light-color);
}

.EstatutosLink:hover {
  color: var(--secondary-dark-color);
}

.EventosFrame {
  width: 100%;
  background-color: var(--primary-dark-color);
  color: var(--primary-light-color);
  display: flex;
  flex-direction: column;
}

.EventosOngoingTitle,
.EventosComingTitle,
.EventosArchiveTitle {
  font-size: 1.8vw;
  color: var(--primary-light-color);
  margin-left: 80px;
  margin-top: 10px;
}

.EventosHeader {
  width: 100%;
  background-color: var(--secondary-dark-color);
  color: var(--secondary-dark-color);
  display: flex;
  flex-direction: row;
  /*align-items: stretch;*/
  justify-content: space-between;
  /*border-radius: 0px 0px 10% 30% /0px 0px 10% 50%;*/
  /*border: solid 1px red;*/
}

.EventosHeaderBar {
  width: 88.3%;
  color: var(--primary-light-color);
  background-color: var(--secondary-dark-color);
  /*display: flex;*/
  /*flex-direction: row;*/
  /*align-items: stretch;*/
  border-radius: 100% 0px 0px 0px /95% 0px 0px 0px;
  margin-bottom: -3px;
  /*border: solid 1px red;*/
}

.EventosHeaderTitle {
  width: 100%;
  background-color: var(--primary-light-color);
  color: var(--primary-dark-color);
  text-align: center;
  font-size: 30px;
  /*border-radius: 0px 30% 0px 0px /0px 100% 0px 0px;*/
  /*border: solid 1px red;*/
  /*padding-left: 30px;*/
  margin: 0px;

  /*display: flex;
  flex-direction: row;
  gap: 2rem;*/
}

.EventosContent {
  width: 100%;
  height: 100%;
  background-color: var(--secondary-dark-color);
  color: var(--secondary-light-color);
  display: flex;
  flex-direction: row;
}

.EventosContentLeft {
  width: 100%;
  height: 10%;
  background-color: var(--primary-light-color);
  color: var(--primary-dark-color);
  display: flex;
  flex-direction: column;
  padding: 0px;
  border-radius: 0px 0px 30% 0%;
}

.EventosContentRight {
  width: 100%;
  height: 100%;
  background-color: var(--primary-light-color);
  color: var(--primary-dark-color);
  display: flex;
  flex-direction: column;
  /* border-radius: 80px 0px 15% 3%/ 120px 0px 10% 7%;*/
}

.EventosFooter {
  width: 100%;
  color: var(--secondary-dark-color);
  background-color: var(--secondary-light-color);
  display: flex;
  flex-direction: row;
}
.EventosFooterBar {
  width: 100%;
  background-color: var(--secondary-dark-color);
  color: var(--secondary-light-color);
  display: flex;
  flex-direction: row;
  border-radius: 0% 0% 100% 100% /0px 0px 250% 60%;
}

.EventosOngoingFrame,
.EventosComingFrame,
.EventosArchiveFrame {
  display: flex;
  flex-direction: column;
}

.EventosOngoingList,
.EventosComingList,
.EventosArchiveList {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: auto;
  margin-left: 0%;
}

.EventoOnGoing,
.EventoComing,
.EventoArchive {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 4%;
  width: 100%;
}

.EvOngBackgound {
  background-color: var(--primary-light-color); /* black;*/
  /*border: solid 1px white;*/
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 35%;
}

.EnOngTitle {
  color: var(--primary-light-color);
  font-size: 1.4vw;
  text-align: center;
  padding: 10px;
}

.EvOngImageFrame {
  /*background-color: var(--primary-dark-color);
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  margin-top: 20%;
  margin-left: 20%;
  width: 75%;
  z-index: +1;*/
  /* margin-bottom: -24px;*/
  object-fit: cover;
  width: 100%;
  height: 50px;
}

.EvOngImage {
  /*height: 75%;*/
  /* padding: 5px;*/
  /*z-index: +1;*/
  /*position: relative;*/

  object-fit: contain;
  width: 20%;
  /*height: 300px;*/
  margin: 2%;
}

.EnOngFooter {
  background-color: white;
  height: 100%;
  width: 100%;
  align-self: end;
  margin: 0px;
  padding: 0px;
}

.EvOngContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 1%;
  justify-content: space-between;
  width: 60%;
}

.EvOngContentTitle {
  /*color: var(--primary-light-color);*/
  font-size: 1.7vw;
  text-align: left;
  padding-left: 5px;
  width: 80%;
}

.EvOngContentSubTitle {
  /*color: var(--primary-light-color);*/
  font-size: 1.5vw;
  text-align: left;
  padding-left: 5px;
}

.EvOngContentText {
  font-size: 1.4vw;
  width: 80%;
  /*padding-bottom: 5%;*/
  padding-left: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
  white-space: pre-line;
}

.EvOngContentWhen,
.EvOngContentwhere,
.EvOngContentContact {
  font-size: 1.4vw;
  padding-left: 5px;
  font-weight: bold;
  white-space: pre-line;
}

.EventosArchiveExternalLink {
  padding-left: 6%;
  padding-bottom: 10px;
}
/*
 {
  font-size: 0.9vw;
  padding-bottom: 1%;
}

.EvOngContentContact {
  font-size: 0.9vw;
  padding-bottom: 1%;
}
*/
.myImg {
  height: 90%;
  /*background-color: var(--primary-light-color);*/
  /*width: auto;*/
  /*margin: 10px;*/
  /*padding: 20px;*/
  /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
}

.myImgBorder {
  height: 400px;
  width: 100%;
  /*background-color: var(--primary-dark-color);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;*/
}

.DestaqueCarouselFrame {
  width: 100%;
  height: 100%;
  padding: 10px;
  color: var(--primary-light-color);
  display: flex;
  align-items: center;
  text-align: left;
}

.destaqueDiaTexto1 {
  margin-left: 0px;
  font-size: 40px;
  font-style: normal;
  font-weight: normal;
  font-family: inherit;
}

.destaqueDiaTexto2 {
  margin-left: 0px;
  font-size: 28px;
  font-style: normal;
  font-weight: normal;
  font-family: fantasy;
}

.destaqueCarousel {
  width: 100%;
  height: 350px;
  margin-top: 15px;
  margin-left: 2%;
  margin-right: 2%;
}

.each-slide {
  background-color: var(--primary-light-color);
}

.images-wrap {
  background-color: var(--primary-light-color);
}
.active {
  background-color: var(--primary-light-color);
}

.slideTeste {
  animation: countdown 5s linear infinite;
  /*background-image: url( "./configs/images/artiset30anos.jpg");*/
}

@keyframes countdown {
  0% {
    background-color: hsl(0, 100%, 20%);
  }
  20% {
    background-color: hsl(90, 100%, 20%);
  }
  40% {
    background-color: hsl(180, 100%, 20%);
  }
  60% {
    background-color: hsl(270, 100%, 20%);
  }
  80% {
    background-color: hsl(360, 100%, 20%);
  }
  100% {
    background-color: hsl(0, 100%, 20%);
  }
}

.myImg {
  height: 90%;
  /*background-color: var(--primary-light-color);*/
  /*width: auto;*/
  /*margin: 10px;*/
  /*padding: 20px;*/
  /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
}

.myImgBorder {
  height: 400px;
  width: 100%;
  /*background-color: var(--primary-dark-color);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;*/
}

.MainMenuBar {
  /*display: flex;
  flex-direction: column;*/

  width: 100%;
  height: 100px;
  background-color: var(--primary-light-color);
  color: var(--primary-dark-color);
  /*border-bottom: solid 1px var(--primary-light-color);*/
  /*border: 1px solid blue;*/
  font-size: 0.8vw;
  position: fixed;
  padding: 0px 0px 20px 0px;
  margin: 0px;
  /*flex-direction: row;*/
  z-index: +2;
}

.MainMenuBarItems {
  /*display: flex;*/
  width: 100%;
  height: 100%;
  color: inherit;
  background-color: inherit;
  font-size: inherit;
  margin: 0px 0px 50px 0px;
  padding: 20px;
  font-size: 0.8vw;
  /*flex-direction: column;
  position: fixed;*/
  /*border: 1px solid red;*/
  box-shadow: #e9ecef 0px 2px 8px 0px;
}

.MainMenuBarItem {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 100%;
  /*padding: 15px 0px 15px 0px;*/
  padding-left: 30px;
  font-size: 15px; /* max(1vw, 12px);*/
  color: inherit;
  background-color: inherit;
  border: 0px;

  vertical-align: middle;
  /*margin-right: 30px;*/
  /*margin-left: 30px;*/
  text-decoration: none;
}

button.MainMenuBarItem:hover {
  color: var(--secondary-dark-color);
}

button.MainMenuBarItemSelected {
  font-weight: bold;
  font-size: 17px;
  /*color: var(--secondary-dark-color);*/
  color: var(--primary-dark-color);
  background-color: inherit;
  border: 0px;
  padding-left: 30px;
}

button.MainMenuBarItemSelected:hover {
  color: var(--secondary-dark-color);
}

.MainMenuBar > ul {
  list-style-type: none;
}

/*
.MainMenuBarItem > li {
  color: var(--primary-dark-color);
  background-color: var(--primary-light-color);
  border: 0px;
  margin-right: 15px;
  margin-left: 15px;
}
*/

.MainMenuBarIconText {
  height: 50px;
  width: auto;
  margin-right: 10px;
  margin-left: 0px;
  background-color: var(--primary-light-color);
  color: var(--primary-dark-color);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /*padding: 0.5px;*/
}

.MainMenuBarIcon {
  height: 50px;
  width: auto;
  margin-right: 10px;
  margin-left: 0px;
  background-color: var(--primary-light-color);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /*padding: 0.5px;*/
}

.MainMenuBarIconFrame {
  height: 80px;
  width: auto;
  margin-right: auto;
  margin-left: 10px;
  /*margin-top: 10px;*/
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: max(1.8vw, 15px);
}

.MainMenuBarIconFrame :hover {
  cursor: pointer;
}

/*.MainMenuBarItem:hover {
  color: var(--secondary-dark-color);
}*/

.SideMenuBarVert {
  display: flex;
  width: 10%;
  color: inherit;
  background-color: inherit;
  font-size: 1vw;
  flex-direction: column;
  align-content: flex-start;
  /* padding-top: 100px;*/
  position: fixed;
}

.SideMenuBarHorz {
  display: flex;
  width: 100%;
  color: inherit;
  background-color: inherit;
  font-size: 0.9vw;
  flex-direction: row;
  align-content: flex-start;
  gap: 2rem;
  padding-top: 10px;
}

/*.SideMenuBarItems {
  width: 100%;
  color: inherit;
  background-color: inherit;
}*/

.SideMenuBarItemHoriz {
  padding: 0px;
  color: inherit;
  background-color: inherit;
  border: 0px;
  font-size: 15px; /*1.5vw;*/
  width: 100%;
  text-align: center;
}

.SideMenuBarItemVert {
  padding: 0px;
  color: inherit;
  background-color: inherit;
  border: 0px;
  font-size: 15px; /*1.5vw;*/
  width: 100%;
  text-align: center;
  /*text-decoration: underline;*/
}

.SideMenuBarItemSelected {
  padding: 0px;
  color: inherit;
  background-color: inherit;
  border: 0px;
  font-size: 15px; /*1.5vw;*/
  width: 100%;
  text-align: center;
  text-decoration: underline;
}

.QuemSomosContentRight button:hover,
.SociosContentRight button:hover {
  color: var(--secondary-dark-color);
}

.EventosContentLeft button:hover,
.AtividadesContentRight button:hover {
  color: var(--primary-dark-color);
}

.AtividadesFormacaoCard {
  color: var(--secondary-light-color);
  background-color: var(--secondary-dark-color);
  margin: 25px;
  padding: 13px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 25%;
}

.FormacaoCardTitle {
  font-weight: bold;
  font-size: 14px;
}

.FormacaoCardContent {
  padding-left: 13px;
  font-size: 13px;
}
.FormacaoCardText {
  /*font-weight: bold;*/
  font-size: 13px;
}

.FormacaoCardHorario {
  font-weight: lighter;
  font-size: 13px;
}

.ProtocolosFormacaoCard {
  color: inherit;
  background-color: inherit;
  display: flex;
  flex-direction: column;
  margin: 2%;
  padding: 20px;
  /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
  width: 20%;
}

.ProtocoloCardTitle {
  font-weight: bold;
  font-size: 1.5vw;
  margin: 5px;
}

.ProtocoloCardContent {
  padding-left: 0px;
  display: flex;
  flex-direction: row;
}
.ProtocoloCardText {
  /*font-weight: bold;*/
  font-size: 13px;
}

.ProtocoloImage {
  object-fit: cover;
  /* max-width: 80%;
  max-height: 100px;
  min-height: 15vw;*/
  /*margin-right: 10px;*/
  /*display: block;*/
  margin: 15px;
  width: 80%;
  height: auto;
  /* padding: 5px;*/
  /*z-index: +1;*/
  /*position: relative;*/
}

.AtividadesEventoItemList {
  display: flex;
  flex-direction: column;
  /* justify-content: left;*/
  margin-top: 4%;
  /*width: auto;*/
}

.AtividadesEventoItemFrame {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.AtividadesEventoItemUpperContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.AtividadesEventoItem {
  margin-left: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.AtividadesEventoItemImageFrame {
  width: 40%;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.AtividadesEventoItemImage {
  max-width: 100%;
}

.btnActEventosFotos {
  background-color: inherit;
  color: inherit;
  border-style: none;
}

.btnActEventosFotos:hover {
  color: var(--secondary-dark-color);
}

.ActEvenFotos {
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 auto;
}

.ActEvenFoto {
  margin: 5px;
  max-width: 20%;
  max-height: 20%;
}

.ActEvenFoto:hover {
  transform: scale(3);
  background-color: var(--primary-dark-color);
  border: 3px solid var(--primary-dark-color);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/*

.ProtocoloCardHorario {
  font-weight: lighter;
  font-size: 1vw;
}
*/

/*
button.SideMenuBarItem:hover {
  color: red; 
}
*/

.content {
  padding: 10px;
  background-color: #eee;
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
}

.SociosGaleria {
  color: var(--primary-dark-color);
  background-color: var(--primary-light-color);
  padding: 10px;
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: column;
}

.SociosGaleriaListFrame {
  background-color: var(--primary-dark-color);
  color: var(--primary-light-color);
  width: 100%;
  height: 20%;
  display: flex;
  flex: 1 1 auto;
}

.SociosGaleriaTitle {
  background-color: inherit;
  color: inherit;
  height: 8%;
  font-size: 2vw;
  padding: 10px;
}

.SociosListItems {
  background-color: var(--primary-light-color);
  color: inherit;
  height: 12%;
  width: 100%;
  font-size: 0.7vw;
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 30%;
  /*max-height: 700px;*/
}

.SociosListItem {
  /*background-color: var(--primary-light-color);
  color: var(--primary-dark-color);*/
  color: var(--primary-light-color);
  background-color: var(--primary-dark-color);
  margin: 10px;
  padding: 10px;
  font-size: 0.7vw;
  /*border-radius: 5px;*/
  /*border-radius: solid 1px;*/
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.SocioName {
  color: inherit;
  font-size: 0.9vw;
  font-weight: bold;
  padding: 0px;
  margin: 0px;
}

.SocioAreas {
  color: inherit;
  font-size: 0.9vw;
  padding: 0px;
}

.SocioLink {
  color: inherit;
  font-size: 0.7vw;
  padding: 0px;
}

/*.SocioName:hover {
  color: var(--secondary-dark-color);
  padding: 0px;
}*/

.AtividadesFrame {
  width: 100%;
  background-color: var(--primary-dark-color);
  color: var(--primary-light-color);
  display: flex;
  flex-direction: column;
}

.AtividadesTitle {
  font-size: 1.8vw;
  color: var(--primary-light-color);
  margin-left: 2%;
}

.AtividadesHeader {
  width: 100%;
  background-color: var(--secondary-light-color);
  color: var(--secondary-dark-color);
  display: flex;
  flex-direction: row;
  /*align-items: stretch;*/

  /*border-radius: 0px 0px 10% 30% /0px 0px 10% 50%;*/
  /*border: solid 1px red;*/
}

.AtividadesHeaderBar {
  width: 100%;
  color: var(--primary-light-color);
  background-color: var(--primary-dark-color);
  /*display: flex;*/
  /*flex-direction: row;*/
  /*align-items: stretch;*/
  border-radius: 100% 0px 0px 0px /30% 0px 0px 0px;
  /*border-radius: 80% 0px 0px 0px /50% 0px 0px 0p*/
  /*border: solid 1px red;*/
}

.AtividadesHeaderTitle {
  width: 15%;
  background-color: var(--secondary-dark-color);
  color: var(--primary-light-color);
  text-align: right;
  font-size: 1.7vw;
  border-radius: 0px 150% 0px 0px /0px 200% 0px 0px;
  /*border: solid 1px red;*/
  /*padding: 10px;*/
  margin: 0px;
  text-align: center;
}

.AtividadesContent {
  width: 100%;
  height: 100%;
  background-color: var(--secondary-dark-color);
  color: var(--secondary-light-color);
  display: flex;
  flex-direction: row;
}

.AtividadesContentLeft {
  width: 100%;
  height: 100%;
  background-color: var(--primary-dark-color);
  color: var(--primary-light-color);
  display: flex;
  flex-direction: column;
  padding: 0px;
  border-radius: 0px 0px 1% 0%/0px 0px 90% 0px;
}

.AtividadesContentRight {
  width: 15%;
  height: auto;
  color: var(--primary-dark-color);
  background-color: var(--primary-light-color);
  display: flex;
  flex-direction: column;

  border-radius: 1px 0px 30% 0%/30px;
}

.AtividadesFooter {
  width: 100%;
  color: var(--secondary-dark-color);
  background-color: var(--secondary-light-color);
  display: flex;
  flex-direction: row;
}
.AtividadesFooterBar {
  width: 100%;
  color: var(--secondary-dark-color);
  background-color: var(--secondary-light-color);
  display: flex;
  flex-direction: row;
  border-radius: 0px 0px 35% 20% /0px 50% 50% 20%;
}

.AtividadesFormacaoTitle,
.AtividadesEventosTitle,
.AtividadesProjetosTitle,
.AtividadesProtocolosTitle {
  font-size: 30px; /*1.8vw;*/
  color: var(--primary-dark-color);
  background-color: var(--primary-light-color);
  /*padding: 20px;*/
  text-align: center;
}

.AtividadesFormacaoFrame,
.AtividadesEventosFrame,
.AtividadesProjetosFrame,
.AtividadesProtocolosFrame {
  display: flex;
  flex-direction: column;
  width: auto;
  color: var(--primary-dark-color);
  background-color: var(--primary-light-color);
  padding-top: 100px;
}

.AtividadesFormacaoList,
.AtividadesProjetosList,
.AtividadesProtocolosList {
  display: flex;
  flex-direction: row wrap;

  width: 90%;
  margin-left: 5%;
  height: auto; /*300px;*/
  font-size: 1.2vw;
  color: var(--primary-dark-color);
  background-color: var(--primary-light-color);
  padding-top: 30px;
  padding-bottom: 30px;
  justify-content: center;
}

.AtividadesEventosList {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: 10%;
  margin-bottom: 10%;
  height: auto; /*300px;*/
  font-size: 1.2vw;
}

/*
.AtividadesEventosList,
.AtividadesProtocolosList {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin-left: 10%;
  height: auto; 
  font-size: 1.2vw;
}*/

.SociosFrame {
  width: 100%;
  background-color: var(--primary-dark-color);
  color: var(--primary-light-color);
  display: flex;
  flex-direction: column;
}

.SociosTitle {
  font-size: 1.8vw;
  color: var(--primary-light-color);
  margin-left: 2%;
}

.SociosHeader {
  width: 100%;
  background-color: var(--secondary-light-color);
  color: var(--secondary-dark-color);
  display: flex;
  flex-direction: row;
  /*align-items: stretch;*/

  /*border-radius: 0px 0px 10% 30% /0px 0px 10% 50%;*/
  /*border: solid 1px red;*/
}

.SociosHeaderBar {
  width: 100%;
  background-color: var(--primary-light-color);
  color: var(--primary-dark-color);
  /*display: flex;*/
  /*flex-direction: row;*/
  /*align-items: stretch;*/
  border-radius: 100% 0px 0px 0px /30% 0px 0px 0px;
  /*border-radius: 80% 0px 0px 0px /50% 0px 0px 0p*/
  /*border: solid 1px red;*/
}

.SociosHeaderTitle {
  width: 15%;
  background-color: var(--primary-dark-color);
  color: var(--primary-light-color);
  text-align: right;
  font-size: 1.7vw;
  border-radius: 0px 150% 0px 0px /0px 200% 0px 0px;
  /*border: solid 1px red;*/
  /*padding: 10px;*/
  margin: 0px;
  text-align: center;
}

.SociosContent {
  width: 100%;
  height: 100%;
  background-color: var(--primary-dark-color);
  color: var(--primary-light-color);
  display: flex;
  flex-direction: row;
}

.SociosContentLeft {
  width: 100%;
  height: 100%;
  color: var(--primary-dark-color);
  background-color: var(--primary-light-color);
  display: flex;
  flex-direction: column;
  padding: 0px;
  border-radius: 0px 0px 1% 0%/0px 0px 90% 0px;
}

.SociosContentRight {
  width: 15%;
  height: auto;
  color: var(--primary-light-color);
  background-color: var(--primary-dark-color);
  display: flex;
  flex-direction: column;

  border-radius: 1px 0px 30% 0%/30px;
}

.SociosFooter,
.ApoiosFooter {
  width: 100%;
  color: var(--secondary-dark-color);
  background-color: var(--secondary-light-color);
  display: flex;
  flex-direction: row;
}
.SociosFooterBar,
.ApoiosFooterBar {
  width: 100%;
  color: var(--secondary-dark-color);
  background-color: var(--secondary-light-color);
  display: flex;
  flex-direction: row;
  border-radius: 0px 0px 35% 20% /0px 50% 50% 20%;
}

.SociosSejaSocioTitle,
.SociosGaleriaTitle {
  font-size: 1.8vw;
  background-color: var(--primary-light-color);
  color: var(--primary-dark-color);
  margin-left: 2%;
}

.SociosSejaSocioFrame,
.SociosGaleriaFrame {
  display: flex;
  flex-direction: column;
}

.SociosSejaSocioList,
.SociosGaleriaList {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-left: 10%;
  height: auto; /*300px;*/
  font-size: 1.2vw;
}

.ApoiosFrame {
  width: 100%;
  background-color: var(--primary-light-color);
  color: var(--primary-dark-color);
  display: flex;
  flex-direction: column;
}

.ApoiosHeaderBar {
  width: 100%;
  border-radius: 100% 0px 0px 0px /30% 0px 0px 0px;
}

.ApoiosHeaderTitle {
  width: 15%;
  background-color: var(--primary-light-color-color);
  color: var(--primary-dark-color);
  text-align: center;
  font-size: 1.7vw;
  /*border-radius: 0px 150% 0px 0px /0px 200% 0px 0px;*/
  margin: 10px 0px 0px 20px;
  text-align: left;
}

.ApoiosContent {
  width: 100%;
  height: 100%;
  background-color: var(--primary-light-color-color);
  color: var(--primary-dark-color);
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 auto;
}

.ApoiosListFrame {
  width: 30%;
  display: flex;
  flex-flow: column;
  margin: 0px 0px 10px 0px;
}

.ApoiosListItemsFrame {
  width: 30%;
  padding: 3%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center space-around;
  flex: 1 1 30%;
  min-height: max-content;
}

.ApoiosListItemFrame {
  width: 100%;
  display: flex;
  flex-flow: row;
}

.ApoioInfoFrame {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  flex: 1 1 30%;
}

.ApoioImage {
  max-width: 100%;
  max-height: 100%;
  height: 150px;
  padding: 15px;
}

.ApoioTitle {
  max-width: 80%;
  min-width: 80%;
  padding: 10px 0px 5px 0px;
  font-size: 1.2vw;
}

.ApoioText {
  max-width: 60%;
  padding: 10px;
  font-size: 1vw;
}

.FooterFrame {
  color: white;
  background-color: #04b3f3;
  padding: 5px;
  margin: 0px;
}

.FooterImg {
  height: 30px;
  margin: 5px;
  border: none;
  margin-left: 10px;
}

.EventosOnGoingSlideShow {
  width: 100%;
  height: 100%;
  margin-top: 1%;
  margin-bottom: 1%;
  padding-left: 0%;
  padding-right: 0%;
  background-color: var(--primary-light-color);
}

.each-slide > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  margin-top: -30px;
}

.flagSelector {
  margin-left: 50px;
  /* border-width: 0px;*/
}

.menu-flags-button {
  border-width: 1px; /*****????*/
  /*border-color: var(--primary-light-color);*/
}

.menu-flags-button:hover {
  background-color: var(--primary-light-color);
  border-color: var(--secondary-dark-color);
}

.collapsible {
  background-color: #777;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.active,
.collapsible:hover {
  background-color: var(--primary-light-color); /* #555;*/
}

.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: #f1f1f1;
}
